/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

/* eslint-disable no-console */

import axios from 'axios';
import Constants from './constants';
import { generateHexString, getDefaultHashObject, stringify } from './helpers';

const {
  BrowserLogLevels,
  Colors,
  LineEnding,
  LoggerName,
  MaxMessageLength,
  MessagePrefix,
  StackDriverHeaderKeys
} = Constants;

const browserLogRequestsPerMinute = {
  currentInterval: 0,
  currentRate: 0,
  maxRate: 30 // requests per minute before logging is disabled
};

let previousMessage;

/**
 * Increments an the current error rate by one tick given the current time in the browser.
 * If the current rate is greater than the max rate then logging is disabled and false is returned.
 * @returns {Boolean} Value indicating logging is disabled
 * @private
 */
const isBrowserLoggingDisabled = () => {
  const interval = Math.floor(Date.now() / 60000) * 60000;

  if (interval !== browserLogRequestsPerMinute.currentInterval) {
    browserLogRequestsPerMinute.currentInterval = interval;
    browserLogRequestsPerMinute.currentRate = 0;
  }

  browserLogRequestsPerMinute.currentRate++;

  return (
    browserLogRequestsPerMinute.currentRate >
    browserLogRequestsPerMinute.maxRate
  );
};

/**
 * Destructs the JSON messageObject into a prettier string format with colors for easier local debugging
 * @param {Object} messageObject Log object automatically generated by sendToStackDriver method
 * @returns {String} flat message
 * @private
 */
const getDevLogMessage = messageObject => {
  const { message, severity, timestamp, ...other } = messageObject;

  const prefix =
    message.indexOf('#Origin: Server') > -1 ? ' [SERVER] ' : ' [CLIENT] ';
  const color = Colors[severity] || Colors.ERROR;

  const otherDataAsText = stringify(other, '    ..');
  const messageSeparator =
    otherDataAsText && !message.endsWith(LineEnding) ? LineEnding : '';

  const text = `\x1b[1;${color}m\x1b[7m${timestamp}${prefix}[${severity}]\x1b[0m ${message}${messageSeparator}${otherDataAsText}`;

  return text;
};

/**
 * Flattens the data structures presented to the function and returns the arguments (as an Array) that
 * should be passed to the sendToStackDriver method.
 * @param {String} key Root area of the application being logged
 * @param {String} method Function name this log entry should be associated with
 * @param {String} message Log message to write
 * @param {Object} [messageData] Object data to associate with the log entry
 * @param {Object} [stackdriverData] Key value pair map to associate with the log entry with the hash sign (keywords)
 * @returns {Object} Arguments intended to be passed to the sendToStackDriver method
 * @private
 */
const getLogArguments = (
  key,
  method,
  message,
  messageData = {},
  stackdriverData = {}
) => {
  if (typeof key !== 'string' || typeof method !== 'string') {
    return {};
  }

  // StackDriver requirements are a string value containing #<key>: <value> format within the message
  const hashObjectData = {
    ...getDefaultHashObject(),
    ...generateStackDriverHeaders(),
    ...stackdriverData
  };

  const logMessage = `${key}:${method} ${message}`;

  const additionalMessageData = [messageData]
    .map(datum => stringify(datum))
    .filter(Boolean)
    .join(LineEnding);

  return {
    ...hashObjectData,
    message: `${MessagePrefix} - ${logMessage}${
      additionalMessageData ? `${LineEnding}${additionalMessageData}` : ''
    }`
  };
};

/**
 * Write the an appropriately formatted log entry to stack driver or proxies
 * the request through node if this code is currently running in the browser
 * @param {StackDriverLogEntry} stackDriverEntry Stackdriver log level to associate this entry with
 * @private
 */
const sendToStackDriver = stackDriverEntry => {
  const { level = 'info', ...messageObjectData } = stackDriverEntry;

  const messageObject = {
    timestamp: new Date().toISOString(),
    severity: level.toUpperCase(),
    'X-Span-Export': false,
    logger_name: LoggerName,
    ...messageObjectData
  };

  const currentMessage = messageObject.message;

  // If we just logged this message don't log it again to avoid infinite loop logging due to
  // console errors potentially out of our control - the previous log entry was sufficient
  if (currentMessage === previousMessage) return;

  // If message too long there we might not need entire data.
  // and also server cannot process huge data.
  if (currentMessage.length >= MaxMessageLength) {
    messageObject.message = currentMessage.substr(0, MaxMessageLength - 1);
  }

  previousMessage = messageObject.message;

  if (process.browser) {
    const shouldLog = BrowserLogLevels.indexOf(messageObject.severity) !== -1;

    if (0 && isBrowserLoggingDisabled()) {
      console.log(
        'stackdriver client side logging has been temporarily disabled',
        browserLogRequestsPerMinute
      );
    } else if (shouldLog) {
      axios
        .post('/server/log', JSON.stringify(messageObject), {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          timeout: 2000
        })
        .catch(() => {}); // It's okay if this fails but we need a no-op to avoid an unhandledrejection
    }
  } else {
    write(messageObject);
  }
};

/**
 * Creates an object to be sent as HTTP Headers for a subsequent request
 * @param {String} [currentTraceId] Current stack driver tracer id received via http header
 * @param {String} [currentSpanId] Current stack driver span id received via http header
 * @param {Boolean} [createNewChild] Flag to determine if a new span id must be returned
 * @returns {Object} Object containing the new http headers for stackdriver association
 * @public
 */
export const generateStackDriverHeaders = (
  currentTraceId,
  currentSpanId,
  createNewChild
) => {
  // honor a traceId if it's been received; otherwise create a new one
  const traceId = currentTraceId || generateHexString(16);

  // use the spanId if it's been received; otherwise create a new one
  const parentSpanId = currentSpanId || generateHexString(16);

  // create a new spanId if requested or there is no current span, otherwise fallback on the new traceId
  const spanId =
    createNewChild || currentSpanId ? generateHexString(16) : traceId;

  const headers = {
    [StackDriverHeaderKeys.traceId]: traceId,
    [StackDriverHeaderKeys.spanId]: spanId
  };

  // Only include the parent if it's different than the current span id
  if (currentSpanId || spanId !== traceId) {
    headers[StackDriverHeaderKeys.parentSpanId] = parentSpanId;
  }

  return headers;
};

/**
 * Formats the given obj and writes it to the console.  This is a low level method
 * that should only be used by objects already formatted.
 * @param {Object} obj StackDriver object to write to the console
 * @public
 */
export const write = obj => {
  // Perform basic validation since this is a public method and exposed
  if (
    obj &&
    obj.logger_name === LoggerName &&
    obj.message &&
    typeof obj.message === 'string' &&
    obj.message.length < MaxMessageLength &&
    obj.severity &&
    typeof obj.severity === 'string' &&
    obj.timestamp &&
    typeof obj.timestamp === 'string'
  ) {
    // Production environment should just log the JSON whereas dev should print a prettier message
    const isProd = process.env.NODE_ENV === 'production';
    const message = isProd ? JSON.stringify(obj) : getDevLogMessage(obj);

    console.info(message);
  }
};

/**
 * Writes data to the stackdriver log file.  If this operation is run from within the web browser
 * the data is sent over the network and then logged to stackdriver through the /log route.
 * @param {String} level Stackdriver log level to associate this entry with
 * @param {String} key Root area of the application being logged
 * @param {String} method Function name this log entry should be associated with
 * @param {String} message Log message to write
 * @param {Object|Error} [messageData] Object data to associate with the log entry
 * @param {Object} [stackdriverData] Key value pair map to associate with the log entry with the hash sign (keywords)
 * @param  {Any[]} [args] Other data to write
 * @public
 */
export const log = (
  level,
  key,
  method,
  message,
  messageData,
  stackdriverData
) => {
  try {
    const logArgs = getLogArguments(
      key,
      method,
      message,
      messageData,
      stackdriverData
    );
    sendToStackDriver({ level, ...logArgs });
  } catch (err) {
    // We really shouldn't be here... allow a console log as sending to stack driver might continue to cause errors
    console.log(
      `${MessagePrefix} - logger.send failed with: ${err &&
        err.message} stack: ${err && err.stack}`
    );
  }
};

export default {
  generateStackDriverHeaders,
  write,
  log
};
