
function readAllQueryParams(){

  const qsParam = new URLSearchParams(window.location.href);
  
  return {
    devMode: qsParam.get('dev'),
  
  }
}

function viewPageReducer(state = {}, action) {

    switch (action.type) {
      case 'INIT': 
        return {
          ...state,
          ...readAllQueryParams(),
          pageName: 'home'
        }; 
      case 'PAGE_CHANGE':
        return {
          ...state,
          pageName: action.payload.pageName
        };
      case 'GET_GEOLOCATION':
        return {
          ...state,
          deviceLatitude: action.payload.deviceLatitude,
          deviceLongitude: action.payload.deviceLongitude
        };  
      default:
        return state;
    }
}

export default viewPageReducer;
  