import React, { useContext } from 'react';
import { StoreContext } from 'context';
import { get } from "lodash";

const OrderDetails = () => {
  const { appState } = useContext(StoreContext);
  const oid = get(appState,'orderDetails.oid');

    return (
        <div className='tw-flex-col tw-flex tw-py-2'>
          <div className='tw-flex tw-py-1'>
            <div className='tw-mr-10 heading'>Order ID</div>
            <div>{oid}</div>
          </div>
        </div>
    );
}

export default OrderDetails;