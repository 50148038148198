import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Button = ( props ) => {

    const { loadingIndicator, handleSubmit, type, value, disabled } = props;

    return (
        <div className='button-container tw-relative'>
            { loadingIndicator && <div className='loader tw-absolute'></div> }
            <input 
                type= {type} 
                value={value}
                className='btn-primary tw-w-full' 
                onClick={ handleSubmit }
                disabled={ disabled }
            />
        </div>
    );
}

Button.defaultProps = {
    loadingIndicator: false,
    type: 'button',
    disabled: false,
}

Button.propTypes = {
    loadingIndicator: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool
}

export default Button;