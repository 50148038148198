
function orderReducer(state = {}, action) {

    switch (action.type) {
        case 'UPDATE_DEL_STATUS':
            return {
                ...state,
                'deliveryStatus': action.payload.deliveryStatus,
                'deliveryStatusText': action.payload.deliveryStatusText
            };
        case 'UPDATE_DEL_STATUS_CODE':
            return {
                ...state,
                'deliveredStatusCode': action.payload.deliveredStatusCode,
                'deliveredStatusCodeText': action.payload.deliveredStatusCodeText
            };
        case 'UPDATE_NOT_DEL_STATUS_CODE':
            return {
                ...state,
                'notDeliveredStatusCode': action.payload.notDeliveredStatusCode,
                'notDeliveredStatusCodeText': action.payload.notDeliveredStatusCodeText
            };
        case 'UPDATE_COMMENTS':
            return {
                ...state,
                'comments': action.payload.comments
            };

        case 'ADD_PHOTO':
            return {
                ...state,
                'base64Photo': action.payload.base64Photo
            };
        case 'REMOVE_PHOTO':
            return {
                ...state,
                'base64Photo': undefined
            };
        case 'ADD_SIGNATURE':
            return {
                ...state,
                'base64Signature': action.payload.base64Signature
            };
        case 'REMOVE_SIGNATURE':
            return {
                ...state,
                'base64Signature': undefined
            };
        case 'PHOTO_SIZE':
            return {
                ...state,
                'base64PhotoSize': action.payload.base64PhotoSize
            };
        case 'SIG_SIZE':
            return {
                ...state,
                'base64SignatureSize': action.payload.base64SignatureSize
            };

        default:
            return state;
    }
}

export default orderReducer;
