

function startupStateReducer(state = {}, action) {

    switch (action.type) {
      case 'INIT':
        return {
            ...window.__startup_state
        }
    
      default:
        return state;
    }
}

export default startupStateReducer;
  