import React,{ useContext } from 'react';
import { StoreContext } from 'context';
import './index.scss';


const DeliveryStatus = () => {
   
    const { dispatch } = useContext(StoreContext);

    const handleInputChange = (event) => {
        
        const target = event.target;

        const deliveryStatus = target && target.getAttribute('status');

        const statusDesc = target.value;
        
        getDeliveryStatus(deliveryStatus, statusDesc);

    }


    const getDeliveryStatus = (deliveryStatus, statusDesc) => {

        dispatch({
            type:'PAGE_CHANGE',
            payload:{
               pageName: 'delivery'
            }
        });

        dispatch({
            type:'UPDATE_DEL_STATUS',
            payload:{
                deliveryStatus,
                deliveryStatusText: statusDesc
            }
        });

      }
    

    return(
        <React.Fragment>
            <div className='heading'>Delivery Status</div>
            <div className='description'>Select a delivery status</div>
            <div className='tw-flex'>
                <input type='button' onClick={handleInputChange} className='btn-secondary' value='Delivered' status='2'/>
                <input type='button' onClick={handleInputChange} className='btn-secondary' value='Not Delivered' status='1'/>
            </div>
        </React.Fragment>
    );
    
}

export default DeliveryStatus;