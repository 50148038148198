import React,{ useContext, useState} from 'react';
import { StoreContext } from 'context';
import Button from 'common/elements/button';
import  "./index.scss";

import SignatureCanvas from 'react-signature-canvas'
import {calculateImageSize} from 'utils'
import Logger from 'logger';
import Svg from 'common/elements/svg';

const SignaturePad = () => {
    
    const {  dispatch } = useContext(StoreContext);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const logger = new Logger('Request');

    let sigPad = {};

    const goBack = () => {
  
      dispatch({
          type:'PAGE_CHANGE',
          payload:{
              pageName: 'delivery'
          }
      });

    }

    const trim = (e) => {
        e.preventDefault();
        const base64SigString = sigPad.getTrimmedCanvas().toDataURL('image/png');
        
        const sizeInKB=calculateImageSize(base64SigString);
        logger.info('Signature Size in KB',sizeInKB);
        
        dispatch({
            type:'ADD_SIGNATURE',
            payload:{
                base64Signature: base64SigString
            }
        });
        dispatch({
            type:'SIG_SIZE',
            payload:{
                base64SignatureSize: sizeInKB
            }
        });
        dispatch({
            type:'PAGE_CHANGE',
            payload:{
                pageName: 'delivery'
            }
        });
      
    }

    return (
        <div className="signature-container">
            <div className='tw-flex tw-justify-end'>
                <Svg 
                    name='close' 
                    className='tw-cursor-pointer icon-close' 
                    viewBox='0 0 12 12' 
                    onClick={ goBack } />
            </div>
            <h3 className='tw-mb-2 title'>Add a Signature</h3>
            <div className='heading'>Sign your name below to confirm that you've received your delivery.</div>
            <div className="signature-pad tw-my-4 tw-p-4">
                <SignatureCanvas canvasProps={{className: 'signature-canvas'}}
                    ref={(ref) => { sigPad = ref }} onBegin={ () => setDisableSubmit( false ) } />
            </div>
            <hr className='divider'/>
            <div className="signature-actions">
                <Button 
                disabled={disableSubmit}
                type='button' 
                handleSubmit={ trim } 
                value='Submit Signature' />
        
            </div>
        </div>

    );
}

export default SignaturePad;