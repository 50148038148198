/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

import { log } from './stackdriver';
import Injector from './injector';
import Timer from './timer';

class Logger extends Timer {
  constructor(key) {
    super();

    this.key = (key || 'global').toLowerCase();
  }

  debug(method, message, messageData, stackdriverData) {
    log('debug', this.key, method, message, messageData, stackdriverData);
  }

  info(method, message, messageData, stackdriverData) {
    log('info', this.key, method, message, messageData, stackdriverData);
  }

  notice(method, message, messageData, stackdriverData) {
    log('notice', this.key, method, message, messageData, stackdriverData);
  }

  warn(method, message, messageData, stackdriverData) {
    log('warning', this.key, method, message, messageData, stackdriverData);
  }

  error(method, message, messageData, stackdriverData) {
    log('error', this.key, method, message, messageData, stackdriverData);
  }

  critical(method, message, messageData, stackdriverData) {
    log('critical', this.key, method, message, messageData, stackdriverData);
  }

  alert(method, message, messageData, stackdriverData) {
    log('alert', this.key, method, message, messageData, stackdriverData);
  }

  emergency(method, message, messageData, stackdriverData) {
    log('emergency', this.key, method, message, messageData, stackdriverData);
  }

  getCurrentState() {
    return Injector.getCurrentState();
  }
}

export default Logger;
