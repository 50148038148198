import React from 'react';
import './index.scss';

import Svg from 'common/elements/svg';

const Logo = () => {

    return(
        <Svg name='ftd-logo' viewBox='0 0 80 80' className='tw-m-auto logo' />
    );

};

export default Logo;