import React,{ useContext } from 'react';
import { StoreContext } from 'context';
import { get } from 'lodash';

import './index.scss';

import OrderDetails from 'components/orderDetails';
import RecipientAdress from 'components/recipientAddress';
import DeliveryStatus from 'components/deliveryStatus';
import FloristDetails from 'components/floristDetails';

import ConfirmDeliveryPage from 'components/confirmDelivery';
import ConfirmationPage from 'components/confirmation';
import Message from 'components/message';
import ImageUpload from 'components/captruePhoto';
import SignaturePad from "components/signaturePad";

const DeliveryForm = () => {

    const { appState } = useContext( StoreContext );
    const pageName = get(appState,'pageDetails.pageName','home');
    
    return(
        <React.Fragment>

                <div className='mainContainer tw-w-full md:tw-flex md:tw-justify-center'>
                    <form className='deliveryDetails tw-w-full'>
                        { pageName === 'home' &&
                            <React.Fragment>
                                <h3 className='tw-mb-2 title'>Order Details</h3>
                                <OrderDetails/>
                                <hr className='divider'/>
                                <RecipientAdress/>
                                <hr className='divider'/>
                                <DeliveryStatus/>
                            </React.Fragment>
                        }
                        { pageName === 'delivery' &&
                            <React.Fragment>
                                <Message/>
                                <ConfirmDeliveryPage />
                            </React.Fragment>
                        }
                        { pageName === 'photo' &&
                          <ImageUpload />
                        }
                        { pageName === 'signature' &&
                          <SignaturePad />
                        }
                    </form>
                    { (pageName === 'home' || 
                        pageName === 'delivery') &&
                        <FloristDetails/>
                    }
                </div>
           
            {  pageName === 'confirmation' &&
                <ConfirmationPage />
            }
        </React.Fragment>
        
    );
    
}

export default DeliveryForm;