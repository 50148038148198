import React, { useContext, useState } from 'react';
import { StoreContext } from 'context';
import { get,isEmpty } from "lodash";
import axios from 'axios';
import moment from 'moment';

import SelectField from 'common/elements/selectField';
import Button from 'common/elements/button';
import TextArea from 'common/elements/textarea';
import Svg from 'common/elements/svg';

import { usePosition } from 'components/geoLocation';
import Modal from 'components/modal';
import Base64Preview from 'components/base64Preview';

import './index.scss';

const isDeliveredStatus = ( appState ) => {
    return get(appState,'orderDetails.deliveryStatus','') === '2';

}

const getDeliveryStatusCode = ( appState ) => {
    
    const selectDeliveryCode = get(appState,'orderDetails.deliveryStatus','');

    switch (selectDeliveryCode) {
        case '1': //undelivered case
            return get(appState,'orderDetails.notDeliveredStatusCode','');
        case '2':
            return get(appState,'orderDetails.deliveredStatusCode','');
        default:
            return '';
    }
}

const getDeliveryStatusCodeText = ( appState ) => {

    const selectDeliveryCode = get(appState,'orderDetails.deliveryStatus','');
    
    switch (selectDeliveryCode) {
        case '1':
            return get(appState,'orderDetails.notDeliveredStatusCodeText','');
        case '2':
         return get(appState,'orderDetails.deliveredStatusCodeText','');
        default:
            return '';
    }
}

const ConfirmDelivery = () => {

    const { appState, dispatch } = useContext(StoreContext);
    const deconPhase2 = get(appState,'startupState.deconPhase2') === 'true';
    const { latitude='', longitude='' } = usePosition();
    const [selectedValue, setSelectedValue] = useState(getDeliveryStatusCode( appState ));
    const [errorMessage, setErrorMessage] = useState('');
    const [commentsText, setCommentsText] = useState(get(appState, 'orderDetails.comments', ''));
    const [ formSubmitIndicator, setFormSubmitIndicator ] = useState(false);
    const selectedDeliveryStatus = get(appState,'orderDetails.deliveryStatus',1);
    const selectedDeliveryStatusText = get(appState,'orderDetails.deliveryStatusText','Deliverable');
    
    const deliveryCodes = get(appState,'startupState.deliveryCodesList',[])
                            .filter( delCode => delCode.delivered === selectedDeliveryStatus );

    // const devMode =  get(appState,'pageDetails.devMode',"0");                           

    let renderOptions =  deliveryCodes.map( delCode => {
        return { 'text': delCode.desc, 'value': delCode.id}
    });

    const base64Photo = get(appState,'orderDetails.base64Photo');

    const base64Signature = get(appState,'orderDetails.base64Signature');

    renderOptions = renderOptions.length > 0 ? renderOptions : [];

    const disableField = renderOptions.length === 0;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalName, setModalName] = useState(null);

    const getContent = () => {
        switch (selectedDeliveryStatusText) {
            case 'Delivered':
                return 'Thanks for delivering a gift and making someones day better. You can leave a note if there is anything youd like us to know.';
            case 'Not Delivered':
                return 'This item was not delivered. Please leave a note so that the florist can follow up with the customer.';
            default:
                return '';
        }
    }

    const getTitle = () => {
        switch (selectedDeliveryStatusText) {
            case 'Delivered':
                return 'Confirm Delivery';
            case 'Not Delivered':
                return 'Undelivered';
            default:
                return '';
        }
    }
  
    const gotoHomePage = () => {

        dispatch({
            type:'PAGE_CHANGE',
            payload:{
                pageName: 'home'
            }
        })

        dispatch({
            type:'HIDE_MESG'
        })
    }

    const addPhotoOrSignature = (event) => {

        const pagaName = event.target.dataset.page;
  
        dispatch({
            type:'PAGE_CHANGE',
            payload:{
               pageName: pagaName
            }
        });
       
    }

    const onSelectChange = ( props ) => {

        const {text, value} = props;

        setSelectedValue(value);

        setErrorMessage(() => { return value === '' ? 'A delivery code is required' : ''});
        
        if(get(appState,'orderDetails.deliveryStatus') === "1"){

            dispatch({
                type:'UPDATE_NOT_DEL_STATUS_CODE',
                payload:{
                    notDeliveredStatusCode: value,
                    notDeliveredStatusCodeText: text
                }
            }) 
        }
        else if(get(appState,'orderDetails.deliveryStatus') === "2"){
            dispatch({
                type:'UPDATE_DEL_STATUS_CODE',
                payload:{
                    deliveredStatusCode: value,
                    deliveredStatusCodeText: text
                }
            });
        }
        
    }

    const validateForm = ( formData ) => {

        let isFormValid = true; 

        const requiredFields = [
            { name:'accid', label:' Account Id'},
            { name:'deliveryStatus', label:'Delivery Status code'},
            { name:'deliveryStatusCodeText', label: 'Delivery code'},
            { name :'oid', label:'Order Id'}
        ]
 
        requiredFields.map( ( field ) => {
                if( isEmpty( formData[field.name] )){

                    isFormValid = false;
                    dispatch({
                        type:'SHOW_MESG',
                        payload:{
                            type: 'error',
                            message: `Required ${field.label} field is missing.`
                        }
                    });
                    return true;
                }
                return false;
        } );

        return isFormValid;
    }

    const handleSubmit = async ( e ) => {
        
        e.preventDefault();

        setErrorMessage(() => { return selectedValue === '' ? 'A delivery code is required' : ''});
       
        const data = {
            
            accid: get(appState,'orderDetails.accid',''),
            deliveryStatus: get(appState,'orderDetails.deliveryStatus',''),
            deliveryStatusCode: getDeliveryStatusCode( appState ),
            deliveryStatusCodeText: getDeliveryStatusCodeText( appState ),
            deviceLatitude: latitude,
            deviceLongitude: longitude,
            oid: get(appState,'orderDetails.oid',''),
            recipadd: get(appState,'orderDetails.recipientDetails.recipadd',''),
            recipcity: get(appState,'orderDetails.recipientDetails.recipcity',''),
            recipstate: get(appState,'orderDetails.recipientDetails.recipstate',''),
            recipzip: get(appState,'orderDetails.recipientDetails.recipzip',''),
            recipphone: get(appState,'orderDetails.recipientDetails.recipphone',''),
            recipname: get(appState,'orderDetails.recipientDetails.recipname',''),  
            reciplat: get(appState,'orderDetails.recipientDetails.reciplat',''),
            reciplong: get(appState,'orderDetails.recipientDetails.reciplong',''),
            memcode : get(appState,'orderDetails.memcode',''),
            timestamp: moment().format(),
            comments: get(appState, 'orderDetails.comments', ''),
            photo: isDeliveredStatus(appState) ? get(appState,'orderDetails.base64Photo') : undefined,
            signature: isDeliveredStatus(appState) ? get(appState,'orderDetails.base64Signature') : undefined
    
        }
        

        if( validateForm(data) ){
            
            setFormSubmitIndicator(true);

            await axios.post('OrderUpdate',JSON.stringify(data),
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                
                },
                timeout: 20000
            })
            .then(response => {
                
                const success = get(response, 'data.ResponseCode', '');

                if(success === 0) {
                    dispatch({
                        type:'PAGE_CHANGE',
                        payload:{
                            pageName: 'confirmation'
                        }
                    });    
                }
                else {
                    dispatch({
                        type:'SHOW_MESG',
                        payload:{
                            type: 'error',
                            message: get(response, 'data.ResponseText', '')
                        }
                    }) 
                }

                setFormSubmitIndicator(false);
            })
            .catch( err => {
               
                dispatch({
                    type:'SHOW_MESG',
                    payload:{
                        type: 'error',
                        message: 'Something went wrong. Please try later.'
                    }
                });

                setFormSubmitIndicator(false);
            })

        }

    }

    const onTextChange = ( event ) => {

        if ( event.target ) {

            const text = event.target.value;

            setCommentsText(text);

            dispatch({
                type:'UPDATE_COMMENTS',
                payload:{
                    comments: text,
                }
            });

        }

    };

    const openModal = (e) => {
        const modalName = e.target.dataset.modal;
        setIsModalOpen(true);
        setModalName(modalName);
    }

    
    const handleDelete = () => {
     
        switch(modalName) {
            case 'Signature':
                dispatch({
                    type: 'REMOVE_SIGNATURE'
                });
                break;
            case 'Photo':
                dispatch({
                    type: 'REMOVE_PHOTO'
                });
                break;
            default:
        }
        setIsModalOpen(false);
    }

    const modalCloseHandler = () => {
        setIsModalOpen(false);
    }


    return (
        <div className='confirmDelivery'>
            <div className='back tw-flex tw-content-center tw-items-center'>
                <Svg 
                    name='back' 
                    className='icon-back tw-cursor-pointer' 
                    viewBox='0 0 11 7.01' 
                    onClick={ gotoHomePage } />
                <input 
                    type='button' 
                    className='btn-back' 
                    value='Back To Delivery Status' 
                    status='' 
                    onClick={ gotoHomePage } 
                />
            </div>            
            <h3 className='title tw-mb-5'>{getTitle()}</h3>
            <div className='heading'>{getContent()}</div>
            <SelectField
                placeholderText='Select a delivery code'
                name='deliveryCode'
                className='deliveryCode-select'
                options={ renderOptions }
                errorMessage={ errorMessage ? errorMessage : '' }
                selectedOption={ selectedValue ? selectedValue: '' }
                onChange={ onSelectChange }
                disabled={ disableField }
            />

            { deconPhase2 && 
                <React.Fragment>
                    <TextArea
                        id='commentBox'
                        maxLength={ 100 }
                        cols={ 20 }
                        rows={ 4 }
                        placeholder='Add a note (optional)'
                        disabled={ disableField }
                        onChange={ onTextChange }
                        value={commentsText}
                    />
                
                    { selectedDeliveryStatus === '2' &&
                        <React.Fragment>
                            {
                                !base64Signature &&
                                <div className='tw-relative sm:tw-inline-flex sm:tw-w-1/2'>
                                    <Svg name="pencil" className='btn-svg tw-absolute' />
                                    <input
                                        type='button'
                                        onClick={addPhotoOrSignature}
                                        className='btn-secondary signature-photo tw-my-2'
                                        value='Add Signature'
                                        data-page='signature'
                                    />
                                </div>
                            }
                            <Base64Preview onDelete={openModal} 
                                base64String={base64Signature}
                                title={'Signature confirmation'}
                                name={'Signature'}/>

                            {
                                !base64Photo &&
                                <div className='tw-relative sm:tw-inline-flex sm:tw-w-1/2'>
                                    <Svg name="upload" className='btn-svg tw-absolute' />
                                    <input
                                        type='button'
                                        onClick={addPhotoOrSignature}
                                        className='btn-secondary signature-photo tw-my-2'
                                        value='Upload Photo'
                                        data-page='photo'
                                    />
                                </div>
                            }
                            <Base64Preview onDelete={openModal} 
                                base64String={base64Photo}
                                title={'Photo Upload'}
                                name={'Photo'}
                                imgClassName={'preview-pic'}/>

                            { isModalOpen &&
                                <Modal name={modalName} isOpen={isModalOpen} 
                                    submitHandler={handleDelete} 
                                    closeHandler={modalCloseHandler}
                                    submitButtonText={'Yes, Delete'}/>
                            }
                        </React.Fragment>
                    }
                </React.Fragment>
            }

            <hr className='divider' />
            <Button 
                type='button' 
                handleSubmit={ handleSubmit } 
                value='Submit confirmation' 
                disabled={ disableField }
                loadingIndicator = {formSubmitIndicator} />
        </div>
    );
}

export default ConfirmDelivery;