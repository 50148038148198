import React from 'react';

import Logo from 'common/elements/logo';

import './index.scss';

const getYear = () => {
    const date = new Date();
    const year = date.getFullYear();
    return year;
}

const Footer = () => {

    return(

        <div className='tw-w-full footer'>
            <Logo className='tw-mb-5' />
            <hr className='divider'/>
            <div className='disclaimer tw-flex tw-flex-col tw-justify-center tw-text-center md:tw-flex-row'>
            <ul className='tw-flex tw-justify-center'>
                <li><a href='http://www.ftdcompanies.com/terms' rel='noopener noreferrer' target='_blank'>Terms and Conditions</a></li>
                <li className='tw-px-2'>|</li>
                <li><a href='http://www.ftdcompanies.com/privacy' rel='noopener noreferrer' target='_blank'>Privacy Policy</a></li>
            </ul>
            <div>&copy; { getYear() } FTD, LLC Downers Grove, IL</div>
            </div>
        </div>
    );

};

export default Footer;