/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

const React = require('react');
const PropTypes = require('prop-types');

const injectorProperty = '__loggerInjectorActive';

const consoleTokenRegex = /%[c|d|f|i|o|s]/;

const states = { inactive: 0, active: 1, unloading: 2 };

let currentState = states.inactive;

/**
 * Returns the current state of the injector
 * @returns {Number} Representation of the current state
 *      - 0 The injector is not active
 *      - 1 The injector is currently active
 *      - 2 The injector has been disabled due to a navigation event
 */
const getCurrentState = () => currentState;

/**
 * Hijacks the browser console info, warn, and error methods to
 * proxy the log data to the server and log to StackDriver
 * @param {Logger} logger instance to use for logging
 * @returns {Boolean} value indicating the console has been hijacked
 */
const createInjector = logger => {
  /**
   * Parses a console message and replaces any token within the
   * message with the corresponding value at the (tokens) array index
   * @param {String} message Console message (first argument)
   * @param {Array} [tokens] Other arguments passed to the console method
   * @returns {Array} Detokenized string and any left over arguments
   */
  const detokenize = (message, tokens) => {
    if (typeof message !== 'string' || !tokens || tokens.length === 0) {
      return [message].concat(tokens);
    }

    const clonedTokens = tokens.slice().reverse();

    let detokenizedMessage = message;
    let match;

    // valid tokens => %c %d %f %i %o %s
    while (
      clonedTokens.length &&
      (match = detokenizedMessage.match(consoleTokenRegex))
    ) {
      let value = clonedTokens.pop();
      const foundToken = match[0];

      switch (foundToken) {
        case '%c': // console color; not supported, purge the css value
          value = '';
          break;
        case '%o': // object syntax
          value = JSON.stringify(value);
          break;
        default:
          break;
      }

      detokenizedMessage = detokenizedMessage.replace(consoleTokenRegex, value);
    }

    // Any left over tokens should get appended to the arguments
    return [detokenizedMessage].concat(clonedTokens.reverse());
  };

  /**
   * Creates a wrapper function to proxy the console method into the
   * appropriate logger function
   * @param {String} level Log level to use for handling the message
   * @param {Function} [nativeMethod] Native console method to invoke
   * @returns {Function} Wrapper around the logger function to invoke
   */
  const proxyConsoleMethod = (level, nativeMethod) => (message, ...args) => {
    if (nativeMethod) {
      // IE could throw an exception (invalid calling object) if the console object
      // is used without the dev tools being opened - swallow exceptions in this case
      try {
        nativeMethod(message, ...args);
      } catch (err) {} /* eslint-disable-line no-empty */
    }

    if (currentState === states.active && typeof logger[level] === 'function') {
      const logArgs = detokenize(message, args);

      logger[level](level.toLowerCase(), ...logArgs);
    }
  };

  const handleError = (method, event) => {
    const error = event && (event.error || event.reason);

    if (error) {
      const { filename = 'unknown', lineno = 0, colno = 0 } = event; // JavaScript ErrorEvent type

      const message = `unhandled exception (${filename}:${lineno}:${colno})`;

      logger.error(method, message, error);
    }
  };

  window.console.info = proxyConsoleMethod('info');
  window.console.warn = proxyConsoleMethod('warn', window.console.warn);
  window.console.error = proxyConsoleMethod('error', window.console.error);

  // TODO: this will not be valid for Single Page Web App
  window.addEventListener('beforeunload', () => {
    currentState = states.unloading;
  });

  window.addEventListener('error', handleError.bind(null, 'error-event'));

  window.addEventListener(
    'unhandledrejection',
    handleError.bind(null, 'promise-rejection-event')
  );

  currentState = states.active;

  return true;
};

class Injector extends React.PureComponent {
  constructor(props) {
    super(props);

    if (process.browser && props.logger && !window[injectorProperty]) {
      window[injectorProperty] = createInjector(props.logger);
    }
  }

  render() {
    return null;
  }
}

Injector.propTypes = {
  logger: PropTypes.object.isRequired
};

Injector.getCurrentState = getCurrentState;

export default Injector;
