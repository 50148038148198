
import { useState, useEffect, useContext } from 'react';
import { StoreContext } from 'context';
import { get } from 'lodash';

export const usePosition = () => {

  const { appState } = useContext(StoreContext);
  const deconPhase2 = get(appState,'startupState.deconPhase2') === 'true';
  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);
    
  const onChange = ({coords}) => {

    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });

  };

  const onError = (error) => {
    setError(error.message);
  };

  useEffect(() => {
    const geo = navigator.geolocation;

    if (!geo) {
      setError('Geolocation is not supported');
      return;
    }

    if( deconPhase2 ){
      const watcher = geo.getCurrentPosition(onChange, onError);
      return () => geo.clearWatch(watcher);
    }

  },[deconPhase2]);

  return {...position, error};

}