/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

export const constants = {
  LoggerName: 'dcon-web',
  MessagePrefix: 'dcon-web',
  MaxMessageLength: 1000,

  LineEnding: '\n',

  Colors: {
    DEBUG: '32', // green
    INFO: '32', // green
    NOTICE: '34', // blue
    WARNING: '33', // yellow
    ERROR: '31' // red
  },

  StackDriverHeaderKeys: {
    parentSpanId: 'X-B3-ParentSpanId',
    spanId: 'X-B3-SpanId',
    traceId: 'X-B3-TraceId'
  },

  BrowserLogLevels: [
    'INFO',
    'NOTICE',
    'WARNING',
    'ERROR',
    'CRITICAL',
    'ALERT',
    'EMERGENCY'
  ]
};

export default constants;
