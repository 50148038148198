import React from 'react';

import Logo from 'common/elements/logo';

const Header = () => {

    return(
        <div className='tw-w-full header tw-bg-black'>
            <Logo />
        </div>
    );

};

export default Header;