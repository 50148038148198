import React, { useContext } from 'react';
import { StoreContext } from 'context';
import { get } from 'lodash';

import './index.scss';

const Message = () => {
  const { appState } = useContext(StoreContext);
  const type = get(appState,'notifications.type');
  const message = get(appState,'notifications.message');

  if(!type){
    return null;
  }
    return (
          <div className={type} role='alert'>
            <span>{message}</span>
          </div>
    );
}

export default Message;