/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
const get = function(name) {
  return ('; ' + document.cookie)
    .split('; ' + name + '=')
    .pop()
    .split(';')
    .shift();
};
//TODO:: handle cookies not found
//TODO:: handle cookies with expiration dateTag
//TODO:: handle cookies with path or domain

const set = function(name, value) {
  document.cookie = `${name}=${value};`;
};

const all = function() {
  return document.cookie.split(';').map(cookieString => {
    const splitCookie = cookieString.split('=');
    return {
      name: splitCookie[0].trimLeft(),
      value: splitCookie[1]
    };
  });
};

export default {
  get,
  set,
  all
};
