import React,{useContext} from 'react';
import {StoreContext} from 'context';

import './index.scss';

const FloristDetails = () => {
    
    const { appState } = useContext( StoreContext );

    const startupState = {
        ...appState.startupState
    };
    
    return(
        <div className="floristDetails tw-flex tw-items-center tw-flex-col tw-my-12 md:tw-justify-center">
            <h3 className="heading">Need Delivery Help?</h3>
            <div>{startupState.businessName}</div>
            <div><a className="tw-underline link" href={'tel:'+startupState.phoneNumber}>{startupState.phoneNumber}</a></div>
        </div>
    );
}

export default FloristDetails;
