import React, { Fragment, useContext } from "react";
import { StoreContext } from 'context';

import { calculateImageSize } from 'utils'
import Logger from 'logger';

import { Camera } from "common/elements/camera";
import Svg from 'common/elements/svg';
import "./index.scss";

export default function CapturePhoto() {
    
    const {  dispatch } = useContext(StoreContext);
    
    const logger = new Logger();

    const changeHandler = blobImg => {

        var reader = new FileReader();
        reader.readAsDataURL(blobImg);
        reader.onloadend = function () {

            var base64data = reader.result;
            const base64String = base64data;
            
            const sizeInKB = calculateImageSize(base64String);
            logger.info('Photo Size in KB', sizeInKB);

            dispatch({
                type: 'ADD_PHOTO',
                payload: {
                    base64Photo: base64String
                }
            });
            dispatch({
                type: 'PHOTO_SIZE',
                payload: {
                    base64PhotoSize: sizeInKB
                }
            });

        }

    }

    const gotoDeliveryPage = () => {

        dispatch({
            type: 'PAGE_CHANGE',
            payload: {
                pageName: 'delivery'
            }
        })
    }

    const clearPhoto = () => {
      dispatch({
          type: 'ADD_PHOTO',
          payload: {
              base64Photo: undefined
          }
      });
    }

    return (
        <Fragment>
            <div className='back tw-flex tw-content-center tw-items-center'>
                <Svg
                    name='back'
                    className='icon-back tw-cursor-pointer'
                    viewBox='0 0 11 7.01'
                    onClick={gotoDeliveryPage} />
                <input
                    type='button'
                    className='btn-back'
                    value='Back To Confirm Delivery'
                    onClick={gotoDeliveryPage}
                />
            </div>
            <h3 className='title tw-mb-5'>Add a Photo</h3>
            <main className="root">
              <Camera
                    onCapture={changeHandler}
                    onClear={clearPhoto}
                    acceptPhoto= {gotoDeliveryPage}
                />
                
            </main>
        </Fragment>
    );
}
