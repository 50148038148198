import React, { useState } from 'react';

import Button from 'common/elements/button';
import Svg from 'common/elements/svg';

import './index.scss';

const Modal = (props) => {

    const { name, isOpen, submitHandler, closeHandler, submitButtonText = 'Submit' } = props;
    const [openModal, setOpenModal] = useState(isOpen);

    const closeModal = () => {
        setOpenModal(false);
        closeHandler && closeHandler();
    }

    return (
        <React.Fragment>
            {openModal &&
                <div className='tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full modal-overlay'>
                    <div className='tw-fixed tw-bg-white tw-p-8 modal-content'>
                        <div className='tw-flex tw-justify-end'>
                            <Svg
                                name='close'
                                className='tw-cursor-pointer icon-close'
                                viewBox='0 0 12 12'
                                onClick={closeModal} />
                        </div>
                        <div className='title tw-mb-2'>{`Delete ${name}?`}</div>
                        <div className='content tw-mb-6'>{`After deleting you will have the option to submit a new ${name}.`}</div>
                        <div className='tw-flex tw-w-full tw-justify-between tw-items-center'>
                            <input
                                type='button'
                                onClick={closeModal}
                                className='btn-secondary tw-my-2 tw-uppercase'
                                value='Cancel'
                            />
                            <Button
                                type='button'
                                handleSubmit={submitHandler}
                                value={submitButtonText} />
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    );
}

export default Modal;