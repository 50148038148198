import { combineReducers } from "../utils";
import orderReducer from './orderReducer'
import startupStateReducer from './startupStateReducer';
import viewPageReducer from "./viewPageReducer";
import notificationsReducer from "./notificationsReducer";

export default combineReducers({
    startupState: startupStateReducer,
    orderDetails: orderReducer,
    pageDetails: viewPageReducer,
    notifications: notificationsReducer,
});