import React from 'react';

import { BaseProvider } from "providers";

import Header from 'common/sections/header';
import DeliveryForm from 'components/deliveryForm';
import Footer from 'common/sections/footer';

const App = () => {
  return (
    <BaseProvider>
      <div className="App">
        <Header/>
        <DeliveryForm />
        <Footer/>
      </div>
    </BaseProvider>
  );
}

export default App;
