
function notificationsReducer(state = {}, action) {

    switch (action.type) {
      case 'SHOW_MESG':
        return {
          ...state, 
          'type': action.payload.type,
          'message': action.payload.message
        };
       case 'HIDE_MESG':
          return {
            ...state,
            'type':'',
            'message':''
          };
      default:
        return state;
    }
}

export default notificationsReducer;
  