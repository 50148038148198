import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactResponsiveSelect from 'react-responsive-select';
import Svg from 'common/elements/svg';

import './index.scss';

const SelectField = (props) => {

    const {
        name,
        className,
        errorMessage,
        disabled,
        placeholderText,
        selectedOption,
        options,
        onChange
    } = props;

    const [selectedValue, setSelectedValue] = useState(selectedOption);

     // Options for the select component should be an object with the following properties - value, text, markup (optional)
     const renderOptions = ( ) => {

        let updatedOptions = [];

        if ( selectedValue === '' ) {

            updatedOptions.push( {
                value: '',
                text: placeholderText
            } );

        }

        options && options.map( ( option, index ) => {

            return updatedOptions.push( {
                value: option.value,
                text: option.text,
                markup: <React.Fragment><Svg className='icon-check' name='check' viewBox='0 0 12 10' />{ option.text }</React.Fragment>,
            } );

        } );

        return updatedOptions;


    };


    const handleChange = ( newValue='' ) => {

        if(selectedValue === newValue.value) {
            return;
        }

        setSelectedValue(newValue.value);

        if ( !onChange ) return;
        onChange( newValue );

    };

    const handleSelectDisplay = ( data ) => {

        const text = ( selectedValue === '' ) ? placeholderText : data.text;

        return <React.Fragment><span className={ classNames({'select-placeholder' : !selectedValue, 'select-value': selectedValue })}>{ text }</span></React.Fragment>;
    }

    return (
        <div
            className={ classNames(
                'selectField',
                'nonNativeSelect',
                className,
                {
                    'has-value': selectedValue,
                    'has-error': errorMessage
                } ) }>
               <ReactResponsiveSelect
                name={ name }
                options={ renderOptions() }
                caretIcon={ <Svg name='back' viewBox='0 0 11 7.01' className='icon-carat' /> }
                selectedValue={ selectedValue }
                onChange={ handleChange }
                customLabelRenderer={ handleSelectDisplay }
                disabled={ disabled }
            />
            { errorMessage !== '' &&
                <div className='select-field-error'>
                    <span>{ errorMessage }</span>
                </div>
            }
        </div>
    );
}

SelectField.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    selectedValue: PropTypes.string.isRequired,
    placeholderText: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    errorMessage: PropTypes.string,
    onChange: PropTypes.func,
};

SelectField.defaultProps = {
    errorMessage: ''
};

export default SelectField;