import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const TextArea = ( props ) => {

    const { className, resize, cols, rows, maxLength, placeholder, disabled, value } = props;

    const handleChange = ( event ) => {

        const value = event.target.value;

        if ( !props.onChange ) return;
        props.onChange( event, value );

    };

    return (
        <textarea
            className={classNames(className, 'tw-w-full tw-p-4' , !resize ? 'resize' : '')}
            maxLength={maxLength}
            cols={cols}
            rows={rows}
            placeholder={placeholder}
            disabled={disabled}
            onChange={handleChange}
            value={value}
        />
    );
}

TextArea.defaultProps = {
    maxLength: 100,
    cols: 20,
    rows: 4,
    resize: false,
    disabled: false,
    value: ''
}

TextArea.propTypes = {
    className: PropTypes.string,
    resize: PropTypes.bool,
    cols: PropTypes.number.isRequired,
    rows: PropTypes.number.isRequired,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    value: PropTypes.string
}

export default TextArea;