/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

import {Cookies} from 'utils';
import Constants from './constants';

const { LineEnding } = Constants;

/**
 * Parses an href url to retrieve the service host and uri to log into Stackdriver
 * @param {String} href Fully qualified href to parse
 * @returns {Object} Object containing a host, service and uri property
 */
export const getStackdriverParsedUri = (href = '') => {
  const hostRegex = /https?:\/\/(.+?)\//;
  const hostMatch = hostRegex.exec(href);

  const host = (hostMatch && hostMatch[1]) || '';

  const segments = href.replace(hostRegex, '').split('/');
  const path = segments.join('/');

  return {
    host,
    path,
    service: segments.splice(0, 1).toString(),
    uri: segments.join('/')
  };
};

/**
 * Queries the Network Information API for connection information if available
 * @returns {String} connection information or Unknown
 */
const getConnectionInfo = () => {
  const connection =
    process.browser &&
    (navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection);

  return !connection
    ? 'Unknown'
    : `${connection.effectiveType} (rtt=${connection.rtt} downlink=${
        connection.downlink
      })`;
};

/**
 * Creates an object based on the request context (server side) or window (browser)
 * @returns {Object} object containing key value pairs to be included in the message
 */
export const getDefaultHashObject = () => {
  const qs = window.location.search.substr(1);
  const uri = qs
    ? window.location.href.replace(window.location.search, '')
    : window.location.href;
  const parsedUri = getStackdriverParsedUri(uri);

  return {
    Origin: 'Client',
    ClientIP: Cookies.get('clientIP'),
    CallerName: 'ep-adminweb-service',
    Host: parsedUri.host,
    Uri: parsedUri.path || '/',
    Params: qs,
    'User-Agent': window.navigator.userAgent,
    Connection: getConnectionInfo()
  };
};

/**
 * Creates a random hexidecimal string of the desired length
 * @param {Number} length Length of the hex key to generate
 * @returns {String} Hex value equal to the desired string length
 */
export const generateHexString = length => {
  const maxlen = 8;
  const min = Math.pow(16, Math.min(length, maxlen) - 1);

  const max = Math.pow(16, Math.min(length, maxlen)) - 1;
  const n = Math.floor(Math.random() * (max - min + 1)) + min;
  let r = n.toString(16);

  while (r.length < length) {
    r = r + generateHexString(length - maxlen);
  }

  return r;
};

/**
 * Converts an object into a flat string of [key]: [value] for StackDriver's LogEntry message
 * @param {Error|Object} data Data structure to be parsed
 * @param {String} [prefix] Prefix to start each key with (defaults to data)
 * @returns {String} Flat string representing the data (one-level)
 */
export const stringify = (data, prefix = 'data') => {
  const strings = [];

  if (data && data instanceof Error) {
    strings.push(`error.code: ${data.code || ''}`);
    strings.push(`error.message: ${data.message || ''}`);
    strings.push(`error.stack: ${data.stack || ''}`);
    strings.push('');
  } else if (data && typeof data === 'object') {
    Object.entries(data).forEach(kvp => {
      strings.push(`${prefix}.${kvp[0]}: ${String(kvp[1])}`);
    });

    strings.push('');
  } else if (data !== null && data !== undefined) {
    strings.push(String(data));
  }

  return strings.length ? strings.join(LineEnding) : null;
};

export default {
  getDefaultHashObject,
  generateHexString,
  getStackdriverParsedUri,
  stringify
};
