import React, {useContext} from 'react';
import { StoreContext } from 'context';
import {get} from 'lodash';
import './index.scss';

import Svg from 'common/elements/svg';

const RecipientAddress = () => {
	const { appState } = useContext(StoreContext);
	const { recipname,recipadd, recipcity, 
		recipstate, recipzip, recipphone} = get(appState,'orderDetails.recipientDetails',{});

	return (
		<div className='recipientAddress tw-flex tw-flex-col md:tw-flex-row'>
			<h3 className='heading tw-mr-10'>Delivering to</h3>
			<div className='deliveryInfo tw-flex tw-justify-between tw-items-center'>
				<div className='deliveryAddress tw-flex tw-flex-col'>
					<div className='customerName'>{recipname}</div>
					<div className='tw-underline link'>{recipadd}, {recipcity}, </div>
					<div className='tw-underline link'>{recipstate} {recipzip}</div>
				</div>

				{ recipphone && <div className='tw-flex tw-flex-col '>
					<div className='callSvg'>
						<a href={`tel:${recipphone}`}>
							<Svg name='call' className='icon-call' width="26.769" height="26.963" viewBox="0 0 26.769 26.963" />
						</a>
					</div>
					<p className='callText tw-text-center tw-mt-2 tw-font-semibold'>CALL</p>
				</div>
				}

			</div>
		</div>
	);
}

export default RecipientAddress;