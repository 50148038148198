
import {  useReducer } from 'react';

import  rootReducer from "reducers"
import { getInitialState } from 'utils';


function useStore() {
  
  return useReducer( rootReducer, getInitialState() );
}

export default useStore;