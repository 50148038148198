
import { startupStateReducer,orderReducer,viewPageReducer, notificationsReducer }  from 'reducers';
import { ACTION_TYPE } from './constants';

// sample QR code: 
// 'https://ftdconfirm.com/delivery-status?v=v1&accid=20&memcode=90-5505-AT&recipadd=1920%20LOTUS%20DR&recipcity=ROUND%20LAKE%20HEIGHTS&recipstate=IL&recipzip=60073-1122&recipphone=(123)456-7890&recipname=Boba%20Fett&oid=676669-1';


// function combineReducersInitState(){
//   return Object.keys(reducers).reduce((acc, curr) => {
//     const slice = reducers[curr](undefined, { type: ACTION_TYPE.INIT });
//     return { ...acc, [curr]: slice };
//   }, {});
// }

function readAllQueryParams(){

  const qsParam = new URLSearchParams(window.location.href);
  
  return {
    accid: qsParam.get('accid'),
    memcode: qsParam.get('memcode'),
    recipientDetails: {
        recipadd: qsParam.get('recipadd'),
        recipcity: qsParam.get('recipcity'),
        recipstate: qsParam.get('recipstate'),
        recipzip: qsParam.get('recipzip'),
        recipphone: qsParam.get('recipphone'),
        recipname: qsParam.get('recipname'),
        reciplat: qsParam.get('reciplat'),
        reciplong: qsParam.get('reciplong')
    },        
    oid: qsParam.get('oid')
  }
}

export function getInitialState() {

    const INIT_ACTION ={
      type: ACTION_TYPE.INIT
    };

    const initState = {
      startupState: startupStateReducer( {}, INIT_ACTION ),
      orderDetails: orderReducer( { ...readAllQueryParams() },INIT_ACTION ),
      pageDetails: viewPageReducer( {},INIT_ACTION ),
      notifications: notificationsReducer( {},INIT_ACTION )
    };
    
    return initState;
}