import React from 'react';

import './index.scss';

import Svg from 'common/elements/svg';

const Confirmation = () => {
    return (
        <div className='confirmation tw-flex tw-flex-col tw-items-center'>
            <h3 className='title'>Thanks! You've sucessfully updated the delivery code.</h3>
            <p className='content'>The order's delivery code and status have been updated with this new information.</p>
            <div className='icon'>
                <Svg name='confirmationFloral' width="122.097" height="101.22" viewBox="0 0 122.097 101.22" />
            </div>
        </div>
    );
}

export default Confirmation; 