import React from 'react';
import PropTypes from 'prop-types';

const Base64Preview = ( props ) => {

    const { name, title, base64String, onDelete, imgClassName } = props;

    if(base64String){
        return <React.Fragment>
                <div className="tw-flex tw-justify-between tw-my-2">
                    <div>{title}</div>
                    <input
                        type="button"
                        onClick={onDelete}
                        className="btn-link tw-cursor-pointer"
                        data-modal={name}
                        value="Delete"
                    />
                </div>
                <div className="preview tw-w-full tw-text-center tw-p-4">
                    <img src={base64String} alt={`${name} preview`} className={`preview-img ${imgClassName}`} />
                </div>
            </React.Fragment>


    }

    return null;
    
}

Base64Preview.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    base64String: PropTypes.string.isRequired,
    onDelete: PropTypes.func,
    imgClassName: PropTypes.string
};
  
Base64Preview.defaultProps = {
    title: 'Preview'
};

export default Base64Preview;