import React, { useState, useRef, useEffect } from "react";
import Measure from "react-measure";
import classNames from "classnames";

import { useUserMedia } from "hooks/useUserMedia";
import { useOffsets } from "hooks/useOffsets";

import Button from 'common/elements/button';

import "./index.scss";

//https://blog.logrocket.com/responsive-camera-component-react-hooks/
const CAPTURE_OPTIONS = {
    audio: false,
    video: { facingMode: "environment" }
};

export function Camera({ onCapture, onClear, acceptPhoto }) {
    const canvasRef = useRef();
    const videoRef = useRef();

    const [container, setContainer] = useState({ width: 0, height: 0 });
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
    const [isFlashing, setIsFlashing] = useState(false);
    const [isPhotoSupported, setIsPhotoSupported] = useState(true);

    const mediaStream = useUserMedia(CAPTURE_OPTIONS);
    const offsets = useOffsets(
        videoRef.current && videoRef.current.videoWidth,
        videoRef.current && videoRef.current.videoHeight,
        container.width,
        container.height
    );

    if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
        videoRef.current.srcObject = mediaStream;
    }

    function handleResize(contentRect) {
        setContainer({
            width: contentRect.bounds.width,
            height: contentRect.bounds.height
        });
    }

    function handleCanPlay() {
        //calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
        setIsVideoPlaying(true);
        videoRef.current.play();
    }

    function handleCapture() {
        const context = canvasRef.current.getContext("2d");

        context.drawImage(
            videoRef.current,
            offsets.x,
            offsets.y,
            container.width,
            container.height,
            0,
            0,
            container.width,
            container.height
        );

        canvasRef.current.toBlob(blob => onCapture(blob), "image/jpeg", 1);
        setIsCanvasEmpty(false);
        setIsFlashing(true);
    }

    function handleClear() {
        const context = canvasRef.current.getContext("2d");
        context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        setIsCanvasEmpty(true);
        onClear();
    }

    useEffect(() => {

        setTimeout(() => {
              if (!mediaStream) {
                    setIsPhotoSupported( false )
                }
        }, 6000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    if (!mediaStream && isPhotoSupported === true) {
        return 'Opening Camera...';
    }
    else if(!mediaStream && isPhotoSupported === false){
        return 'Camera access is either blocked or not suppoerted in your browser';
    }

    return (
        <Measure bounds onResize={handleResize}>
            {({ measureRef }) => (
                <div className="wrapper">
                    <div
                        className="container"
                        ref={measureRef}
                        maxHeight={videoRef.current && videoRef.current.videoHeight}
                        maxWidth={videoRef.current && videoRef.current.videoWidth}
                        style={{
                            maxWidth: `${videoRef.current && videoRef.current.videoWidth}px`,
                            maxHeight: `${videoRef.current && videoRef.current.videoHeight}px`
                        }}>
                        <video
                            className="video"
                            ref={videoRef}
                            hidden={!isVideoPlaying}
                            onCanPlay={handleCanPlay}
                            autoPlay
                            playsInline
                            muted
                        />

                        <div className="overlay" hidden={!isVideoPlaying} />

                        <canvas
                            className="canvas"
                            ref={canvasRef}
                            width={container.width}
                            height={container.height}
                        />

                        <div
                            className={classNames(
                                "flash",
                                isFlashing.toString() ? "flashAnimation" : ""
                            )}
                            flash={isFlashing}
                            onAnimationEnd={() => setIsFlashing(false)}
                        />
                    </div>
                    
                    {!isCanvasEmpty && (
                        <div className="tw-flex tw-py-4 tw-justify-between tw-w-full">
                            <Button 
                                type='button' 
                                handleSubmit={ acceptPhoto } 
                                value='Submit Photo' />
                            <input type='button' onClick={handleClear} className='btn-secondary btn-retake-photo' value='Retake Photo' />
                        </div>
                    )}

                    {isCanvasEmpty && (
                        <div className="tw-flex tw-p-1 tw-my-4 btn-take-photo">
                        <input type='button' onClick={handleCapture} className='btn-take-photo tw-appearance-none tw-bg-black' value='' />
                        </div>
                    )}
                </div>

            )}
        </Measure>
    );
}
