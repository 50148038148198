export function calculateImageSize(base64String){
    let padding, inBytes, base64StringLength;
    if(base64String.endsWith("==")) padding = 2;
    else if (base64String.endsWith("=")) padding = 1;
    else padding = 0;

    base64StringLength = base64String.length;
 
    inBytes =(base64StringLength / 4 ) * 3 - padding;
    console.log(inBytes);
    const kbytes = inBytes / 1000;
    return kbytes;
  }
