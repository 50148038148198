
import React from 'react';

import {  StoreContext } from 'context';
import useStore from 'hooks';


const BaseProvider = ( { children } ) => {

  const [ appState, dispatch ] = useStore();

  return (
    <StoreContext.Provider value={{ appState,dispatch }}>
        {children}
    </StoreContext.Provider>
  );

};

export default BaseProvider;
